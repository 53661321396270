$('a[href^=\\#]').on('click', function(event){
    event.preventDefault();
    $('html,body').animate({scrollTop:$(this.hash).offset().top}, 500);
});

jQuery(document).ready(function(){
  $('.js-input-change').next().val('');
});

$('.js-input-change').on('keyup change', function() {
  $(this).next().val($(this).val());
});

// BURGER ICON
function updateMenuButton() {
	$('.burger').find('.burger-icon').toggleClass('is-active');
}
$(document).ready(function() {
	$('.burger').click(function(e){
		e.preventDefault();
		updateMenuButton();
	});
});

// MENU HIDE
$(".burger").click(function() {
    $(".nav").toggleClass("show");

    $(this).toggleClass("close");
});
//

// ACCORDION NAV
jQuery(document).ready(function(){
	var accordionsMenu = $('.cd-accordion-menu');

	if( accordionsMenu.length > 0 ) {
		accordionsMenu.each(function(){
			var accordion = $(this);
			//detect change in the input[type="checkbox"] value
			accordion.on('change', 'input[type="checkbox"]', function(){
				var checkbox = $(this);
				// console.log(checkbox.prop('checked'));
				( checkbox.prop('checked') ) ? checkbox.siblings('ul').attr('style', 'display:none;').slideDown(300) : checkbox.siblings('ul').attr('style', 'display:block;').slideUp(300);
			});
		});
	}
});
//

// NEWS FILTERING
function blogFilter() {
  var sections = $('.news--list-item');
  function updateContentVisibility(){
      var checked = $(".news--filter :checkbox:checked");
      if(checked.length){
          sections.hide();
          checked.each(function(){
              $("." + $(this).val()).show();
          });
      } else {
          sections.show();
      }
  }

  $(".news--filter :checkbox").click(updateContentVisibility);
  updateContentVisibility();
};
blogFilter();
//

// ACCORDION
$('.js--accordion').on('click', function(){

if($(this).children().hasClass('open')) {
    $(this).children().removeClass('open');
  } else {
    // $('.accord-title').removeClass('open');
    // $('.accord-content').removeClass('open');
    $(this).children().addClass('open')
  }
});
//

// CLAIMS SELECTER
$(function() {
  $('#claimselect').change(function(){
    $('.claim--type').hide();
    $('.claims--button').hide();
    $('.' + $(this).val()).show();
  });
});
//

// tooltips
$('.tooltip').tooltipster({
    contentAsHTML: true,
});

// UPDATE INFORMATION CHECKBOXES
var checkboxes = document.getElementsByClassName("-check");

for (var i=0; i<checkboxes.length; i++)  {
  if (checkboxes[i].type == 'checkbox')   {
    checkboxes[i].checked = false;
  }
}
//

// DISABLED BROKER LINKS
$('.disabled').on('click', function(e) {
    e.preventDefault();
});
//
